import React, { memo } from "react";

const MultiArrowDown = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="14"
    viewBox="0 0 22 14"
  >
    <g fill="#383D45">
      <path d="M14.188 6.974c0 .37.128.717.364.971l4.778 5.156a1.215 1.215 0 001.8 0 1.46 1.46 0 000-1.942l-3.857-4.162 3.857-4.162a1.46 1.46 0 000-1.942 1.2 1.2 0 00-.9-.393c-.321 0-.664.139-.9.393l-4.778 5.11a1.42 1.42 0 00-.364.971z" />
      <path d="M7.244 7.945l4.775 5.156a1.213 1.213 0 001.8 0 1.461 1.461 0 000-1.942L9.963 6.997l3.854-4.162a1.461 1.461 0 000-1.942 1.198 1.198 0 00-.9-.393 1.25 1.25 0 00-.899.393L7.244 6.05c-.492.486-.492 1.364 0 1.896z" />
      <path d="M.74 7.955l4.764 5.147a1.21 1.21 0 001.794 0 1.46 1.46 0 000-1.939L3.453 7.01 7.32 2.83a1.46 1.46 0 000-1.939A1.194 1.194 0 006.422.5c-.32 0-.662.138-.897.392L.762 6.04C.25 6.547.25 7.424.741 7.955z" />
    </g>
  </svg>
));

export default MultiArrowDown;
