import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

import { ChevronIcon } from "../../svg";

import { cn, useGlobalState, useDictionary } from "../../helpers";

const Auth = ({ isVisible, onSuccess }) => {
  const dictio = useDictionary();
  const { api, mapSlug } = useGlobalState();
  const [password, setPassword] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsChecking(true);
  };

  useEffect(
    () => {
      if (!isChecking) return;
      api
        .checkMapAuth({ mapSlug, password })
        .then(onSuccess)
        .catch(() => setError(dictio("auth.invalid")))
        .finally(() => {
          setIsChecking(false);
        });
    },
    [isChecking]
  );

  useEffect(
    () => {
      if (!error) return;
      setError("");
    },
    [password]
  );

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="auth"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="Auth"
        >
          <div className="Auth__inner">
            <h2
              className="Auth__title"
              dangerouslySetInnerHTML={{ __html: dictio("auth.title") }}
            />
            <div className={cn("Auth__form", isChecking && "is-loading")}>
              <form onSubmit={handleSubmit}>
                <input
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
                {!isChecking && error && (
                  <div className="Auth__error">{error}</div>
                )}
                <button className="Auth__submit" type="submit" disabled={!password || isChecking}>
                  <ChevronIcon />
                  {dictio("auth.submit")}
                </button>
              </form>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Auth.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Auth;
