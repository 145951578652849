import React, { useState, useEffect } from "react";
import { motion, useSpring, useAnimation } from "framer-motion";
import {
  Map as Leaflet,
  TileLayer,
  Rectangle,
  ZoomControl,
} from "react-leaflet";
import L from "leaflet";
import PropTypes from "prop-types";

import SpotMarker from "../../../commons/components/SpotMarker";
import CookiesBox from "../../../commons/components/CookiesBox";

import { cn, useGlobalState, usePageTracking } from "../../../commons/helpers";
import { DEBUG, MAP, SPOTS } from "../../config";

/**
 * Map
 */
const Map = ({
  isIdle,
  isZooming,
  onMapReady,
  onViewportChanged,
  onMarkerClick,
  onMarkerEnter,
  onMarkerLeave,
  onZoomStart,
  onZoomEnd,
  animateSpots,
  setAnimateSpots,
  onViewClick,
  tilesPath,
  children,
  onLocaleChanged,
  onViewChanged,
  GATag,
  // Sonata methods and bools
  isSonata = false, // @TODO Replace by prop-types
  onMapClick,
  onMarkerDragEnd,
  // From URL
  locale,
  viewId,
}) => {
  const activateGATracking = usePageTracking(GATag, isSonata);

  useEffect(
    () => {
      if (isSonata) return;
      onViewChanged(viewId);
      onLocaleChanged(locale);
    },
    [locale, viewId]
  );
  const mapCenter = L.latLngBounds(MAP.VIEW_BOUNDS).getCenter();

  // Calculate the offset in percent between the center of the "world" and the current center coordinate
  const calculateOffset = (centerValue, value) => {
    const offset = ((centerValue - value) / (centerValue * 2)) * 100 + "%";
    return offset;
  };

  const {
    view,
    lat,
    lng,
    zoom,
    minZoom,
    flatSpots,
    viewMarkers,
    activeSpot,
    hoveredSpot,
    showTutorial,
    showMap,
  } = useGlobalState();

  const [areTilesLoaded, setAreTilesLoaded] = useState(false);

  // Derived state
  const center = [lat, lng];
  const isFrozen = !!activeSpot || showTutorial;
  const isDraggable = isFrozen || zoom === minZoom;
  const showSpots = !showTutorial && !isZooming;

  // Transitions for scale and opacity => we want something similar to a CSS transition
  const defaultTransition = { ease: [0, 0, 0.25, 1], duration: 0.75 };
  const cloudsControls = useAnimation();
  // Springs for the position => we want to make it natural
  const spring = { stiffness: 50, damping: 30 };
  const cloudsX = useSpring("0%", spring);
  const cloudsY = useSpring("0%", spring);

  const setCloudsPosition = ({
    lat,
    lng,
    zoom,
    transition = defaultTransition,
  }) => {
    if (lat) cloudsY.set(calculateOffset(mapCenter.lat, lat));
    if (lng) cloudsX.set(calculateOffset(mapCenter.lng, lng));
    if (zoom) {
      cloudsControls.start({
        opacity: zoom >= 5 ? 0 : 1,
        scale: minZoom > 0 ? zoom / minZoom : 1,
        transition,
      });
    }
  };

  return (
    <div
      className={cn(
        "Map",
        areTilesLoaded && "are-tiles-loaded",
        isIdle && "is-idle",
        !showMap && !isSonata && "is-hidden",
        isSonata && "is-sonata"
      )}
    >
      <Leaflet
        className="Map__element"
        center={center}
        zoom={zoom}
        maxBounds={MAP.VIEW_BOUNDS}
        maxBoundsViscosity={1}
        maxZoom={MAP.MAX_ZOOM}
        crs={MAP.CRS}
        dragging={!isDraggable}
        scrollWheelZoom={!isFrozen}
        touchZoom={!isFrozen}
        doubleClickZoom={!isFrozen}
        zoomControl={false}
        attributionControl={false}
        onViewportChanged={viewport => {
          const [lat, lng] = viewport.center;
          setCloudsPosition({ lat, lng, zoom: viewport.zoom });
          onViewportChanged(viewport);
        }}
        whenReady={event => onMapReady(event.target)}
        onzoomstart={onZoomStart}
        onzoomend={onZoomEnd}
        wheelPxPerZoomLevel={MAP.WHEEL_SENSITIVITY} // Defaults to 60
        // Animations
        onViewportChange={({ center }) => {
          if (isZooming) return;
          const [lat, lng] = center;
          setCloudsPosition({ lat, lng });
        }}
        onzoomanim={({ zoom, center }) => {
          const { lat, lng } = center;
          setCloudsPosition({ lat, lng, zoom });
        }}
        // onClick handler for back-office purposes
        onClick={map => isSonata && onMapClick(map.latlng)}
      >
        {!isSonata && (
          <motion.div
            className="Map__clouds"
            animate={cloudsControls}
            style={{
              x: cloudsX,
              y: cloudsY,
            }}
          />
        )}

        <TileLayer
          url={tilesPath + "/{z}/{x}/{y}.png"}
          onLoad={() => {
            if (areTilesLoaded) return;
            setAreTilesLoaded(true);
          }}
        />

        {showSpots &&
          flatSpots.map((spot, index) => {
            const isActiveMarker = activeSpot && spot.id === activeSpot.id;
            const isHovered = hoveredSpot === spot.id;

            // When there is an active spot => hide all others
            const isMarkerShown = activeSpot
              ? isActiveMarker && !isZooming
              : true;

            return isMarkerShown ? (
              <SpotMarker
                key={`${view}_${spot.id}_${index}`}
                position={[spot.lat, spot.lng]}
                width={24}
                height={SPOTS.LEVELS[spot.height]}
                index={index}
                showLabel={isSonata || zoom >= SPOTS.MINZOOM}
                spot={spot}
                active={isActiveMarker}
                hovered={isHovered}
                onClick={() => onMarkerClick(spot)}
                onMouseOver={() => onMarkerEnter(spot.id)}
                onMouseLeave={() => onMarkerLeave(spot.id)}
                animated={animateSpots}
                isLast={index === flatSpots.length - 1}
                setAnimateSpots={setAnimateSpots}
                draggable={isSonata}
                onDragEnd={event =>
                  onMarkerDragEnd && onMarkerDragEnd(spot, event.target._latlng)
                }
              />
            ) : null;
          })}

        {showSpots &&
          viewMarkers &&
          viewMarkers[viewId] &&
          viewMarkers[viewId].map((marker, index) => (
            <SpotMarker
              key={`${view}_${marker.id}_${index}`}
              type="view"
              position={[marker.lat, marker.lng]}
              width={42}
              height={200}
              index={index}
              onClick={() => onViewClick(marker.id)}
              animated={animateSpots}
            />
          ))}

        {/* DEBUG ISLAND BOUNDS */}
        {DEBUG.BOUNDS && <Rectangle bounds={MAP.INNER_BOUNDS} color="red" />}

        {isSonata && <ZoomControl position="topright" />}
      </Leaflet>

      {children}
      {!isSonata && <CookiesBox onAccept={activateGATracking} />}
    </div>
  );
};

Map.propTypes = {
  isIdle: PropTypes.bool.isRequired,
  isZooming: PropTypes.bool.isRequired,
  onMapReady: PropTypes.func.isRequired,
  onViewportChanged: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  onMarkerEnter: PropTypes.func.isRequired,
  onMarkerLeave: PropTypes.func.isRequired,
  onZoomStart: PropTypes.func.isRequired,
  onZoomEnd: PropTypes.func.isRequired,
  animateSpots: PropTypes.bool.isRequired,
  setAnimateSpots: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  tilesPath: PropTypes.string.isRequired,
  locale: PropTypes.string,
  viewId: PropTypes.string,
  onLocaleChanged: PropTypes.func,
  onViewChanged: PropTypes.func,
  children: PropTypes.node,
  isSonata: PropTypes.bool.isRequired,
  onMapClick: PropTypes.func,
  onMarkerDragEnd: PropTypes.func,
};

Map.defaultProps = {
  isSonata: false,
};

export default Map;
