import { createRouteGetter } from "../commons/helpers";

const routes = {
  home: "/:locale/:viewId",
  spot: "/:locale/:viewId/spot/:spotId",
  page: "/:locale/:viewId/page/:pageName",
};

const useRoutes = (hasSpecificDomain) => {
  return createRouteGetter(routes, hasSpecificDomain);
};

export default useRoutes;
