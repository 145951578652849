import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { cn, useDictionary } from "../../helpers";

const Toast = ({ message, code, showUI }) => {
  const string = useDictionary();

  // If the api error occurs before the dictionnary is loaded
  // we can't get a dictionary string
  const displayedMessage =
    string(message) || "Oops ! We had a problem getting your data.";

  return (
    <motion.div
      className={cn("Toast", showUI && "is-elevated")}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: [0.175, 0.885, 0.32, 1], duration: 0.4 }}
    >
      <p className="Toast__message">{displayedMessage}</p>
      <p className="Toast__code">{code.toUpperCase()}</p>
      <a className="Toast__link" href="/">
        Reload
      </a>
    </motion.div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  code: PropTypes.string,
  showUI: PropTypes.bool.isRequired,
};

Toast.defaultProps = {
  showUI: false,
};

export default Toast;
