import axios from "axios";
import { DEMO_LOCALES } from "../commons/config";

export default class Api {
  constructor(notifyError) {
    this.notifyError = notifyError;
  }

  checkMapAuth = ({ mapSlug, password }) => {
    return axios
      .post(`/datas/${mapSlug}/check.json`, {
        password,
      });
  };

  getStartData = () => {
    return axios
      .get("/start")
      .then((res) => res.data)
      .catch(() =>
        this.notifyError({
          code: "START",
          message: "error.map_fetch",
        })
      );
  };

  getMapData = ({ mapSlug = null }) => {
    const endpoint = mapSlug ? `/datas/${mapSlug}/map.json` : "/datas/map.json";
    return axios
      .get(endpoint)
      .then((res) => res.data.map)
      .catch(() =>
        this.notifyError({
          code: `MAP_${mapSlug}`,
          message: "error.map_fetch",
        })
      );
  };

  getViewData = ({ mapSlug, locale, viewId, fetchDictionary = false }) => {
    const urls = [
      `/datas/${mapSlug}/${locale}/views/${viewId}/spots.json`,
    ];

    if (fetchDictionary) urls.push(`/datas/${mapSlug}/${locale}/dictionary.json`);

    // In order to demo new locales to client we want to fetch local json instead of backend generated data
    if (DEMO_LOCALES.includes(locale)) {
      urls[0] = `/demo-locales/${locale}/${viewId}/spots.json`;
      urls[2] = `/demo-locales/${locale}/dictionary.json`;
    }

    return axios
      .all(urls.map((url) => axios.get(url)))
      .then(
        axios.spread((spots, dictionary) => {
          return {
            spots: spots.data,
            ...(dictionary && {
              dictionary: dictionary.data,
            }),
          };
        })
      )
      .catch(() =>
        this.notifyError({
          code: `VIEW_${locale}_${viewId}_${fetchDictionary ? 1 : 0}`,
        })
      );
  };

  getSpotData = ({ mapSlug, locale, viewId, spotId }) => {
    let url = `/datas/${mapSlug}/${locale}/view/${viewId}/spot/${spotId}.json`;

    if (DEMO_LOCALES.includes(locale)) {
      url = `/demo-locales/${locale}/${viewId}/${spotId}.json`;
    }

    return axios
      .get(url)
      .then((response) => response.data)
      .catch(() =>
        this.notifyError({
          code: `SPOT_${locale}_${viewId}_${spotId}`,
          message: "error.spot_fetch",
        })
      );
  };
}
