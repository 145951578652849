import ReactDOM from "react-dom";
import React, { useState, useEffect, useRef } from "react";
import { Router, Redirect, navigate } from "@reach/router";
import Cookie from "js-cookie";
import L from "leaflet";
import "../commons/helpers/patch";

import Layout from "./components/Layout";
import Map from "./components/Map";
import Modal from "./components/Modal";
import ContentPage from "./components/ContentPage";

import Api from "../commons/api";
import {
  flattenSpots,
  generateViewMarkers,
  useLogger,
  debounce,
} from "../commons/helpers";
import { DictionaryProvider } from "../commons/helpers/dictionary";
import { GlobalStateProvider } from "../commons/helpers/global-state";
import { getPreferredLocale } from "../commons/helpers/locale";
import useRoutes from "./routes";
import { DEBUG, MAP, MODAL, COOKIES, DEFAULT_MAPSLUG } from "./config";

import TagManager from "react-gtm-module";

const App = () => {
  /**
   * Refs
   */
  const map = useRef(null);
  const minZoom = useRef(0);
  const modal = useRef(null);

  /**
   * States and Effects
   */

  // Critical data
  const [hasSpecificDomain, setHasSpecificDomain] = useState(null);
  const [mapSlug, setMapSlug] = useState(null);
  const [locales, setLocales] = useState(null);
  const [locale, setLocale] = useState(null);
  const [dictionary, setDictionary] = useState({});
  const [view, setView] = useState("1");
  const [spots, setSpots] = useState([]);
  const [viewMarkers, setViewMarkers] = useState({});

  // Map
  const [position, setPosition] = useState({ lat: 0, lng: 0, zoom: -1 });
  const [showTutorial, setShowTutorial] = useState(
    !Cookie.get(COOKIES.SEEN_TUTORIAL)
  );
  const [showAuth, setShowAuth] = useState(false);
  const [isZooming, setIsZooming] = useState(false);
  const [isIdle, setIsIdle] = useState(true);
  const [tilesPath, setTilesPath] = useState(`/tiles/${view}`);

  // UI
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showUI, setShowUI] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showMapLoader, setShowMapLoader] = useState(false);
  const [animateSpots, setAnimateSpots] = useState(true);
  const [activeSpot, setActiveSpot] = useState(null);
  const [hoveredSpot, setHoveredSpot] = useState(null);
  const [showContentPage, setShowContentPage] = useState(false);
  const [GATag, setGATag] = useState(null);

  const [apiError, setApiError] = useState(null);
  const api = new Api(setApiError);

  const getRoute = useRoutes(hasSpecificDomain);

  DEBUG.LOGGER && useLogger("MAP UPDATE", position);

  const redirectToLocale = ({ locales, viewIds }) => {
    const locale = getPreferredLocale(locales);
    const homeRoute = getRoute("home", { mapSlug, locale, viewId: viewIds[0] });
    navigate(homeRoute);
  };

  // Animate the idle map when zoom is at its minimum
  useEffect(
    () => {
      const shouldAnimate = position.zoom === minZoom.current;
      setIsIdle(shouldAnimate);
    },
    [position]
  );

  // Animate spots when the view or the UI changes
  useEffect(
    () => {
      !showTutorial && setAnimateSpots(true);
    },
    [showTutorial, spots, view]
  );

  useEffect(
    () => {
      if (!mapSlug || hasSpecificDomain) return;
      fetchMapData();
    },
    [mapSlug]
  );

  // Fetches new data when locale or view is changed
  useEffect(
    () => {
      if ((false === hasSpecificDomain && !mapSlug) || !locales || !locale)
        return;
      if (!locales.available[locale].enabled && !Cookie.get(`auth-${mapSlug}`))
        return setShowAuth(true);
      document
        .querySelector("html")
        .setAttribute("lang", locales.available[locale].isoCode);
      setApiError(null);
      setShowUI(false);
      setFetching("locale");
    },
    [locale, mapSlug]
  );

  useEffect(
    () => {
      if (isInitialFetch) return;
      setApiError(null);
      setFetching("view");
    },
    [view]
  );

  useEffect(
    () => {
      if (!fetching) return;
      const timeout = setTimeout(fetchViewData, 400);
      return () => clearTimeout(timeout);
    },
    [fetching]
  );

  // When window goes fullscreen we recalculate the island position on the screen
  const handleDocumentResize = debounce(() => {
    map.current.invalidateSize();
  }, 750);

  useEffect(() => {
    window.addEventListener("resize", handleDocumentResize);

    return () => window.removeEventListener("resize", handleDocumentResize);
  }, []);

  const fetchMapData = () => {
    api.getMapData({ mapSlug }).then(mapData => {
      const localesObject = {
        available: mapData.locales,
        defaultLocale: mapData.defaultLocale,
      };
      setLocales(localesObject);
      setDictionary(mapData.dictionary);
      setViewMarkers(generateViewMarkers(mapData.viewIds));

      if (mapData.domain) setMapSlug(mapData.slug);
      if (mapData.tagGA) setGATag(mapData.tagGA);

      const foundLocale = mapData.locales[locale];
      // Map is disabled or locale is disabled => show auth
      if (
        (!mapData.enabled || (foundLocale && !foundLocale.enabled)) &&
        !Cookie.get(`auth-${mapSlug}`)
      )
        return setShowAuth(true);

      // Map is enabled and has no locale or wanted locale doest not exist on map => get preferred
      if (!locale || !foundLocale)
        return redirectToLocale({
          locales: localesObject,
          viewIds: mapData.viewIds,
        });
      // Everything is good => get the next data
      document.querySelector("html").setAttribute("lang", foundLocale.isoCode);
      return setFetching("locale");
    });
  };

  const fetchViewData = () => {
    // Only show map loader after a given timeout
    const timeout = setTimeout(() => setShowMapLoader(true), 400);
    map.current &&
      map.current.fitBounds(MAP.INNER_BOUNDS, {
        animate: false,
      });

    api
      .getViewData({
        mapSlug,
        locale,
        viewId: view,
        fetchDictionary: "locale" === fetching,
      })
      .then(({ spots, dictionary: localizedDictionary }) => {
        setFetching(false);
        setShowMapLoader(false);
        clearTimeout(timeout);

        setSpots(spots);
        const viewIds = Object.keys(viewMarkers);
        const viewIndex = viewIds.findIndex(viewId => viewId === view);
        setTilesPath(`/tiles/${viewIndex + 1}`);
        if ("locale" === fetching) setDictionary(localizedDictionary);

        setShowMap(true);
        setShowUI(true);
        isInitialFetch && setIsInitialFetch(false);
      });
  };

  /**
   * Handlers
   */

  const toggleTutorial = isTutorialShown => {
    setShowTutorial(isTutorialShown);
    isTutorialShown && setShowDrawer(false);
  };

  const toggleDrawer = isDrawerOpen => {
    setShowDrawer(isDrawerOpen);
  };

  const handleAppMount = () => {
    api.getStartData().then(res => {
      setHasSpecificDomain(res.domain);
      if (res.domain) {
        fetchMapData();
      }
    });
  };

  const handleSlugChanged = mapSlug => {
    if (!mapSlug) return;
    setMapSlug(mapSlug);
  };

  const handleLocaleChanged = newLocale => {
    setLocale(newLocale);
    setActiveSpot(null);
    setSpots([]);
    setShowDrawer(false);
  };

  const handleViewChanged = newView => {
    setShowMap(false);
    setView(newView);
    setSpots([]);
  };

  const handleMapReady = mapInstance => {
    map.current = mapInstance;
    map.current.fitBounds(MAP.INNER_BOUNDS);
  };

  const handleViewportChanged = viewport => {
    if (-1 === position.zoom) {
      minZoom.current = viewport.zoom;
      map.current.options.minZoom = viewport.zoom;
    }

    let [lat, lng] = [viewport.center[0], viewport.center[1]];

    // Re-centers the map on minZoom level
    if (viewport.zoom === minZoom.current) {
      const islandCenter = L.latLngBounds(MAP.INNER_BOUNDS).getCenter();
      lat = islandCenter.lat;
      lng = islandCenter.lng;
    }

    setPosition({ lat, lng, zoom: viewport.zoom });
  };

  const handleZoomClick = modifier => {
    setPosition({ ...position, zoom: position.zoom + modifier });
  };

  const handleResetClick = () => {
    setShowMap(false);
    setTimeout(() => {
      map.current.fitBounds(MAP.INNER_BOUNDS, {
        animate: false,
      });
      setShowMap(true);
    }, 400);
  };

  const getActiveLatLng = spot => {
    const projectedPoint = map.current.project(
      [spot.lat, spot.lng],
      MAP.MAX_ZOOM
    );
    const offsetPoint = [(modal.current.offsetWidth + MODAL.OFFSET) / 2, 0];
    const targetPoint = projectedPoint.add(offsetPoint);

    return map.current.unproject(targetPoint, MAP.MAX_ZOOM);
  };

  /**
   * Triggered when a spot is clicked, either on a marker or on in the Header nav list
   */
  const handleSpotClick = spot => {
    const spotRoute = getRoute("spot", {
      mapSlug,
      locale,
      viewId: view,
      spotId: spot.id,
    });
    navigate(spotRoute);
  };

  const handleModalMount = id => {
    const allSpots = flattenSpots(spots);
    const activeSpot = allSpots.find(spot => spot.id == id);

    if (!activeSpot) {
      return setApiError({
        code: `SPOT_${locale}_${view}_${id}`,
        message: "error.spot_not_found",
      });
    }

    const { lat, lng } = getActiveLatLng(activeSpot);
    setApiError(null);
    setActiveSpot(activeSpot);
    // TODO Set zoom duration relative to current zoom => max zoom distance
    setPosition({ lat, lng, zoom: MAP.MAX_ZOOM });
    setShowDrawer(false);

    api
      .getSpotData({
        mapSlug,
        locale,
        viewId: view,
        spotId: id,
      })
      .then(spot => {
        setActiveSpot(spot);
      });
  };

  const handleModalClose = () => {
    const homeRoute = getRoute("home", { mapSlug, locale, viewId: view });

    setActiveSpot(null);
    setPosition({ ...position, zoom: position.zoom - 1 });
    navigate(homeRoute);
  };

  const handleViewClick = viewId => {
    const homeRoute = getRoute("home", { mapSlug, locale, viewId });
    navigate(homeRoute);
  };

  const handleSuccessAuth = () => {
    setShowAuth(false);
    Cookie.set(`auth-${mapSlug}`, "1");

    if (locale) {
      document
        .querySelector("html")
        .setAttribute("lang", locales.available[locale].isoCode);
      return setFetching("locale");
    }

    redirectToLocale({ locales, viewIds: Object.keys(viewMarkers) });
  };

  const handleContentPageMount = () => {
    setShowDrawer(false);
    setShowContentPage(true);
    setActiveSpot(null);
  };

  const handleContentPageClose = () => {
    setShowContentPage(false);
  };

  const globalState = {
    api,
    mapSlug,
    locale,
    locales,
    lat: position.lat,
    lng: position.lng,
    zoom: position.zoom,
    minZoom: minZoom.current,
    view,
    spots,
    flatSpots: flattenSpots(spots),
    viewMarkers,
    activeSpot,
    hoveredSpot,
    showDrawer,
    showTutorial,
    showAuth,
    showContentPage,
    showUI,
    showMap,
    getRoute,
    map: map.current,
  };

  return (
    <DictionaryProvider dictionary={dictionary}>
      <GlobalStateProvider state={globalState}>
        <Router>
          <Layout
            apiError={apiError}
            path={false === hasSpecificDomain ? "/:mapSlug" : "/"}
            isLoaderShown={isInitialFetch}
            showMapLoader={showMapLoader}
            toggleTutorial={toggleTutorial}
            toggleDrawer={toggleDrawer}
            onAppMount={handleAppMount}
            onSlugChange={handleSlugChanged}
            onSuccessAuth={handleSuccessAuth}
            onZoomClick={handleZoomClick}
            onSpotClick={handleSpotClick}
            onSpotMouseEnter={spotId => setHoveredSpot(spotId)}
            onSpotMouseLeave={() => setHoveredSpot(null)}
            onResetClick={handleResetClick}
          >
            <Map
              path=":locale/:viewId"
              tilesPath={tilesPath}
              animateSpots={animateSpots}
              setAnimateSpots={setAnimateSpots}
              GATag={GATag}
              isIdle={isIdle}
              isZooming={isZooming}
              onLocaleChanged={handleLocaleChanged}
              onViewChanged={handleViewChanged}
              onViewportChanged={handleViewportChanged}
              onMapReady={handleMapReady}
              onMarkerClick={handleSpotClick}
              onMarkerEnter={spotId => setHoveredSpot(spotId)}
              onMarkerLeave={() => setHoveredSpot(null)}
              onZoomStart={() => setIsZooming(true)}
              onZoomEnd={() => setIsZooming(false)}
              onViewClick={handleViewClick}
            >
              {!animateSpots && (
                <Modal
                  setRef={modal}
                  path="spot/:spotId"
                  onModalMount={handleModalMount}
                  onModalClose={handleModalClose}
                />
              )}
              <ContentPage
                path="page/:pageName"
                onContentPageMount={handleContentPageMount}
                onContentPageClose={handleContentPageClose}
              />
            </Map>
          </Layout>
          {/* Base redirect to default map */}
          {false === hasSpecificDomain && (
            <Redirect from="/" to={`/${DEFAULT_MAPSLUG}`} noThrow />
          )}
        </Router>
      </GlobalStateProvider>
    </DictionaryProvider>
  );
};

const tagManagerArgs = { gtmId: "GTM-WWQBZD4C" };
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.querySelector("#app-root"));
