import {
  DEBUG,
  SPOTS,
  MAP,
  MODAL,
  MEDIA_TYPES,
  COOKIES,
  DEFAULT_MAPSLUG
} from "../commons/config";

SPOTS.MINZOOM = 6;

export {
  DEBUG,
  SPOTS,
  MAP,
  MODAL,
  MEDIA_TYPES,
  COOKIES,
  DEFAULT_MAPSLUG
};
