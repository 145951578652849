import React, { memo } from "react";

const ArrowLeft = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 20 15"
  >
    <path d="M.067 7.525a.8.8 0 00-.011.602.798.798 0 00.165.256l6.095 6.325c.3.31.794.319 1.104.019.31-.3.319-.795.02-1.106L2.652 8.654l16.552.29a.781.781 0 00.793-.77.781.781 0 00-.766-.796L2.68 7.089l4.957-4.794A.786.786 0 007.108.948a.78.78 0 00-.557.22L.238 7.275c-.015.014-.02.034-.033.049a.763.763 0 00-.138.2z" />
  </svg>
));

export default ArrowLeft;
