import L from "leaflet";
import { MAP } from "../config";

/**
 * This is a patch file for Leaflet that allows us to change the duration of the zoom animation
 * The duration is set as a config vaiable MAP.ZOOM_DURATION
 * Patch inspired by this issue: https://gis.stackexchange.com/a/228391
 */

L.Map.prototype._animateZoom = function(center, zoom, startAnim, noUpdate) {
  if (startAnim) {
    this._animatingZoom = true;

    // remember what center/zoom to set after animation
    this._animateToCenter = center;
    this._animateToZoom = zoom;

    L.DomUtil.addClass(this._mapPane, "leaflet-zoom-anim");
  }

  // @event zoomanim: ZoomAnimEvent
  // Fired on every frame of a zoom animation
  this.fire("zoomanim", {
    center: center,
    zoom: zoom,
    noUpdate: noUpdate
  });

  // Work around webkit not firing 'transitionend', see https://github.com/Leaflet/Leaflet/issues/3689, 2693
  setTimeout(L.bind(this._onZoomTransitionEnd, this), MAP.ZOOM_DURATION - 50);
};
