import React from "react";
import PropTypes from "prop-types";

import Spinner from "../Spinner";

import { cn } from "../../helpers";

const Loader = ({ isLoading }) => (
  <div className={cn("Loader", isLoading && "is-loading")}>
    <Spinner delay={0} />
  </div>
);

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Loader;
