import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Cookie from "js-cookie";
import PropTypes from "prop-types";

import { useDictionary, useGlobalState } from "../../helpers";
import { COOKIES } from "../../config";

const CookiesBox = ({ onAccept }) => {
  const dictio = useDictionary();
  const acceptCookies = Cookie.get(COOKIES.ACCEPT_COOKIES);
  const [showCookiesBox, setShowCookiesBox] = useState(!acceptCookies);
  const { locale, mapSlug, view, getRoute } = useGlobalState();

  const handleClick = (accept) => {
    Cookie.set(COOKIES.ACCEPT_COOKIES, accept ? 1 : 0, { expires: 30 });
    accept && onAccept();
    setShowCookiesBox(false);
  };

  useEffect(() => {
    if ("1" === acceptCookies) {
      onAccept();
    }
  }, []);

  return !showCookiesBox ? null : (
    <div className="CookiesBox">
      <div>
        {dictio("cookies.text")}
        <Link
          to={getRoute("page", {
            mapSlug,
            locale,
            viewId: view,
            pageName: "mentions",
          })}
        >{dictio("cookies.more")}</Link>
      </div>
      <footer className="CookiesBox__actions">
        <button onClick={() => handleClick(false)}>{dictio("cookies.decline")}</button>
        <button onClick={() => handleClick(true)}>{dictio("cookies.accept")}</button>
      </footer>
    </div>
  );
};

CookiesBox.propTypes = {
  onAccept: PropTypes.func.isRequired,
};

export default CookiesBox;
