import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDictionary } from "../../../commons/helpers";

const Tutorial = ({ onHide }) => {
  useEffect(() => {
    window.addEventListener("click", onHide);
  }, []);

  const string = useDictionary();

  return (
    <div className="Tutorial" onClick={event => event.stopPropagation()}>
      <div
        className="Tutorial__intro"
        dangerouslySetInnerHTML={{ __html: string("tutorial.content") }}
      />
      <div className="Tutorial__main">
        <h2 className="Tutorial__title">{string("tutorial.title")}</h2>
        <div className="Tutorial__controls">
          <div className="Tutorial__controls__item">
            <span>{string("tutorial.zoom")}</span>
            <img src="/img/tutorial-zoom.png" alt={string("tutorial.zoom")} />
          </div>
          <div className="Tutorial__controls__item">
            <span>{string("tutorial.move")}</span>
            <img src="/img/tutorial-move.png" alt={string("tutorial.move")} />
          </div>
        </div>
        <button className="Tutorial__skip" onClick={onHide}>
          {string("tutorial.skip")}
        </button>
      </div>
    </div>
  );
};

Tutorial.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default Tutorial;
