import React, { useEffect } from "react";
import { Link } from "@reach/router";
import PropTypes from "prop-types";

import { CrossIcon } from "../../../commons/svg";
import { useDictionary, useGlobalState } from "../../../commons/helpers";

const ContentPage = ({ pageName, onContentPageMount, onContentPageClose }) => {
  const { getRoute, mapSlug, locale, view: viewId } = useGlobalState();

  const string = useDictionary();

  useEffect(() => {
    onContentPageMount();

    return () => onContentPageClose();
  }, []);

  const content = string("page." + pageName); // Should be an object title / body

  const body = !content.footer
    ? content.body
    : `
    ${content.body}<br>
    <footer>
      ${Object.values(content.footer)
        .map(value => `<div>${value}</div>`)
        .join("")}
    </footer>
  `;

  if ("string" === typeof content) {
    return null;
  }

  return (
    <div className="ContentPage">
      <div className="Modal">
        <div className="Modal__main">
          <header className="Modal__header">
            <h2 className="Modal__title">{content.title}</h2>
            <Link
              to={getRoute("home", {
                mapSlug,
                locale,
                viewId,
              })}
            >
              <CrossIcon />
            </Link>
          </header>
          <div className="Modal__inner wysiwyg">
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {"group" === mapSlug && "mentions" === pageName && (
              <div id={`beyableoptout${locale.toUpperCase()}`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ContentPage.propTypes = {
  pageName: PropTypes.string,
  onContentPageMount: PropTypes.func.isRequired,
  onContentPageClose: PropTypes.func.isRequired,
};

export default ContentPage;
