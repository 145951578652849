import React from "react";
import PropTypes from "prop-types";

import { ZoomInIcon, ZoomOutIcon } from "../../../commons/svg";

import { MAP } from "../../config";

const Controls = ({ disabled, onZoomClick, zoom, minZoom }) => {
  return (
    <div className="Controls">
      <button
        className="Controls__item"
        disabled={disabled || zoom >= MAP.MAX_ZOOM}
        onClick={() => onZoomClick(MAP.ZOOM_INCREMENTS)}
      >
        <ZoomInIcon />
      </button>
      <button
        className="Controls__item"
        disabled={disabled || zoom <= minZoom}
        onClick={() => onZoomClick(-MAP.ZOOM_INCREMENTS)}
      >
        <ZoomOutIcon />
      </button>
    </div>
  );
};

Controls.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onZoomClick: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  minZoom: PropTypes.number.isRequired,
};

export default Controls;
