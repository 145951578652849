import React from "react";
import PropTypes from "prop-types";

import { VideoIcon, LinkIcon, ImageIcon } from "../../svg";
import { MEDIA_TYPES } from "../../config";
import { cn } from "../../../commons/helpers";

const AttachmentsList = ({ attachments, onItemClick, activeIndex }) => {
  const renderAttachment = ({ media, index, isActive }) => {
    switch (media.type_id) {
      case MEDIA_TYPES.VIDEO:
        return (
          <button
            className={cn("AttachmentsList__item", isActive && "is-active")}
            onClick={() => onItemClick(index)}
          >
            <VideoIcon />
            <span>{media.legend}</span>
          </button>
        );

      case MEDIA_TYPES.LINK:
        return (
          <a
            className={cn("AttachmentsList__item", isActive && "is-active")}
            href={media.url_site}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
            <span>{media.legend}</span>
          </a>
        );

      case MEDIA_TYPES.IMAGE:
        return (
          <button
            className={cn("AttachmentsList__item", isActive && "is-active")}
            onClick={() => onItemClick(index)}
          >
            <ImageIcon />
            <span>{media.legend || "Image"}</span>
          </button>
        );

      default:
        return null;
    }
  };

  return (
    <ul className="AttachmentsList">
      {attachments.map((media, index) => {
        if (!media) return null;

        return (
          <li key={`${index}_${media.type_id}`}>
            {renderAttachment({
              media,
              index,
              isActive: index === activeIndex,
            })}
          </li>
        );
      })}
    </ul>
  );
};

AttachmentsList.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      type_id: PropTypes.number.isRequired,
      legend: PropTypes.string,
      url_video: PropTypes.string,
      url_site: PropTypes.string,
      url_image: PropTypes.string,
    })
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default AttachmentsList;
