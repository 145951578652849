import React, { createContext, useContext } from "react";

const GlobalStateContext = createContext({});

const GlobalStateProvider = ({ children, state }) => (
  <GlobalStateContext.Provider value={state}>
    {children}
  </GlobalStateContext.Provider>
);

const useGlobalState = () => useContext(GlobalStateContext);

export { GlobalStateProvider, useGlobalState };
