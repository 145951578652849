import React, { memo } from "react";

const Reset = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <path
      fill="#FFF"
      d="M1.743 17l2.991 2.993L0 24.727 3.273 28l4.735-4.734L11 26.258V17zm24.422 0H17v9.165l2.962-2.961L24.76 28 28 24.759l-4.797-4.796zM3.273 1L0 4.006l4.68 4.297L1.743 11H11V2.501L7.953 5.297zm22.983 10l-2.992-2.991L28 3.273 24.728 0l-4.736 4.737L17 1.744V11z"
    />
  </svg>
));

export default Reset;
