import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

import SpotsList from "../SpotsList";
import { YoutubeIcon, TwitterIcon, LinkedinIcon } from "../../../commons/svg";

import { cn, useDictionary, useGlobalState } from "../../../commons/helpers";

const Drawer = ({
  isOpen,
  toggleTutorial,
  onSpotClick,
  onSpotMouseEnter,
  onSpotMouseLeave,
}) => {
  const string = useDictionary();

  const { mapSlug, locale, view: viewId, getRoute } = useGlobalState();

  return (
    <div className={cn("Drawer", isOpen && "is-open")}>
      <SpotsList
        onSpotClick={onSpotClick}
        onSpotMouseEnter={onSpotMouseEnter}
        onSpotMouseLeave={onSpotMouseLeave}
      />
      <nav className="Drawer__nav">
        <button
          onClick={event => {
            event.stopPropagation();
            toggleTutorial(true);
          }}
          className="Drawer__nav__link"
        >
          {string("nav.tutorial")}
        </button>
        <Link
          to={getRoute("page", {
            mapSlug,
            locale,
            viewId,
            pageName: "mentions",
          })}
          className="Drawer__nav__link"
        >
          {string("nav.legal")}
        </Link>
        <a
          href={string("nav.contact.url")}
          className="Drawer__nav__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {string("nav.contact")}
        </a>
        <Link
          to={getRoute("page", {
            mapSlug,
            locale,
            viewId,
            pageName: "credits",
          })}
          className="Drawer__nav__link"
        >
          {string("nav.credits")}
        </Link>
        <a
          href={string("nav.veolia.url")}
          className="Drawer__nav__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Veolia website
        </a>
      </nav>
      <div className="Drawer__social">
        <span>{string("social.follow_us")} :</span>
        <nav className="Drawer__social__nav">
          <a
            href={string("social.linkedin.url")}
            className="Drawer__social__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon />
          </a>
          <a
            href={string("social.twitter.url")}
            className="Drawer__social__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href={string("social.youtube.url")}
            className="Drawer__social__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon />
          </a>
        </nav>
      </div>
      <div className="Drawer__copyright">
        &copy; {new Date().getFullYear()} Veolia
      </div>
    </div>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleTutorial: PropTypes.func.isRequired,
  onSpotClick: PropTypes.func.isRequired,
  onSpotMouseEnter: PropTypes.func.isRequired,
  onSpotMouseLeave: PropTypes.func.isRequired,
};

export default Drawer;
