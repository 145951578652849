import React, { useState } from "react";
import PropTypes from "prop-types";
import Magnifier from "react-magnifier";

import Spinner from "../Spinner";

import { VideoIcon, ImageIcon } from "../../svg";

import { cn } from "../../helpers";
import { MEDIA_TYPES } from "../../config";

const Media = ({ type_id, legend, url_video, url_image }) => {
  const [isLoading, setIsLoading] = useState(type_id === MEDIA_TYPES.VIDEO);

  const renderMedia = () => {
    switch (type_id) {
      case MEDIA_TYPES.VIDEO:
        return (
          <iframe
            src={url_video}
            frameBorder="0"
            allowFullScreen
            onLoad={() => setIsLoading(false)}
          />
        );

      case MEDIA_TYPES.IMAGE:
        return <Magnifier src={url_image} mgShape="square" />;

      default:
        return null;
    }
  };

  const renderIcon = () => {
    switch (type_id) {
      case MEDIA_TYPES.VIDEO:
        return <VideoIcon />;

      case MEDIA_TYPES.IMAGE:
        return <ImageIcon />;

      default:
        return null;
    }
  };

  return (
    <figure
      className={cn(
        "Media",
        isLoading && "is-loading",
        type_id === MEDIA_TYPES.VIDEO && "is-video"
      )}
    >
      <div className="Media__inner">
        <div className="Media__spinner">
          <Spinner delay={0} />
        </div>
        {renderMedia()}
      </div>
      {legend && (
        <figcaption className="Media__legend">
          {renderIcon()}
          {legend}
        </figcaption>
      )}
    </figure>
  );
};

Media.propTypes = {
  type_id: PropTypes.number.isRequired,
  legend: PropTypes.string,
  url_video: PropTypes.string,
  url_image: PropTypes.string,
};

export default Media;
