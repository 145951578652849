import React, { memo } from "react";

const ArrowRight = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="15"
    viewBox="0 0 21 15"
  >
    <path d="M20.44 7.199a.8.8 0 010 .602.798.798 0 01-.169.253l-6.204 6.217a.78.78 0 01-1.105 0 .784.784 0 010-1.106l4.873-4.883H1.28a.782.782 0 010-1.566h16.556l-4.873-4.879A.786.786 0 0113.514.5c.201 0 .4.076.553.228l6.206 6.218c.014.015.019.034.032.05.053.06.103.126.135.203z" />
  </svg>
));

export default ArrowRight;
