import React from "react";
import PropTypes from "prop-types";

import Controls from "../Controls";
import { ResetIcon } from "../../../commons/svg";

import { useGlobalState } from "../../../commons/helpers";

const Footer = ({ onZoomClick, onResetClick }) => {
  const {
    zoom,
    minZoom,
    activeSpot,
    showTutorial,
    showContentPage,
  } = useGlobalState();
  
  const areControlsDisabled = !!activeSpot || showTutorial || showContentPage;

  return (
    <div className="Footer">
      <span className="Footer__copyright">
        &copy; {new Date().getFullYear()} Veolia
      </span>
      <Controls
        disabled={areControlsDisabled}
        onZoomClick={onZoomClick}
        minZoom={minZoom}
        zoom={zoom}
      />
      <button
        className="Footer__reset"
        disabled={areControlsDisabled || zoom === minZoom} // Only enables zoom when map is already zoomed
        onClick={onResetClick}
      >
        <ResetIcon />
      </button>
    </div>
  );
};

Footer.propTypes = {
  onZoomClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
};

export default Footer;
