import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

import { cn, useGlobalState } from "../../helpers";

const LocaleLink = ({ to, label }) => {
  const isActive = ({ isPartiallyCurrent }) => ({
    className: cn("LocaleSwitcher__link", isPartiallyCurrent && "is-active"),
  });

  return (
    <Link
      getProps={isActive}
      to={to}
      onClick={event => event.stopPropagation()}
    >
      {label}
    </Link>
  );
};

const LocaleSwitcher = () => {
  const { locales, mapSlug, view: viewId, getRoute } = useGlobalState();

  if (!locales) return null;
  
  const orderedLocales = Object.entries(locales.available)
    .filter(([_, { enabled }]) => !!enabled)
    .map(([locale, { position }]) => ({ locale, position }))
    .sort((a, b) => a.position - b.position);

  if (1 === orderedLocales.length) return null;

  return (
    <nav className="LocaleSwitcher">
      {orderedLocales.map(({ locale }) => {
        const to = getRoute("home", { mapSlug, locale, viewId });
        return <LocaleLink key={locale} to={to} label={locale} />;
      })}
    </nav>
  );
};

LocaleLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
export default LocaleSwitcher;
