import React, { memo } from "react";

const MultiArrowDown = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="26"
    viewBox="0 0 16 26"
  >
    <g fill="#EC1C24">
      <path d="M7.968 9c.455 0 .882-.158 1.195-.448L15.51 2.67a1.495 1.495 0 000-2.215c-.654-.607-1.736-.607-2.39 0L7.996 5.202 2.874.455c-.654-.607-1.736-.607-2.39 0A1.476 1.476 0 000 1.563c0 .395.17.817.484 1.107l6.289 5.882c.313.29.74.448 1.195.448z" />
      <path d="M9.163 17.545l6.346-5.877a1.493 1.493 0 000-2.213c-.654-.607-1.736-.607-2.39 0l-5.123 4.743-5.122-4.743c-.654-.607-1.736-.607-2.39 0A1.474 1.474 0 000 10.56c0 .396.17.817.484 1.107l6.346 5.877c.597.607 1.679.607 2.333 0z" />
      <path d="M9.175 25.55l6.335-5.862a1.489 1.489 0 000-2.208c-.653-.605-1.733-.605-2.386 0L8.01 22.21l-5.142-4.758c-.653-.604-1.733-.604-2.386 0A1.47 1.47 0 000 18.557c0 .395.17.815.483 1.105l6.335 5.862c.625.63 1.704.63 2.357.026z" />
    </g>
  </svg>
));

export default MultiArrowDown;
