import L from "leaflet";

/**
 * Debug config
 */

const DEBUG = {
  BOUNDS: false,
  LOGGER: true,
};

/**
 * Route config
 */

const DEFAULT_MAPSLUG = "group";

/**
 * Spots config
 */

const SPOTS = {
  RADIUS: 24,
  LEVELS: {
    1: 60,
    2: 90,
    3: 120,
  },
  ANIMATION: {
    duration: 400,
    delay: 75,
  },
  MINZOOM: 5,
};

/**
 * Map config
 */

const MAP = {
  VIEW_BOUNDS: [0.0, -12939, 20000, 0],
  INNER_BOUNDS: null,

  MAX_ZOOM: 7,
  ZOOM_INCREMENTS: 1,
  ZOOM_DURATION: 1000, // Duration of zoom animation in ms

  MAX_RESOLUTION: 1,
  MIN_RESOLUTION: null,
  CRS: null,
  WHEEL_SENSITIVITY: 600,
};

MAP.MIN_RESOLUTION = Math.pow(2, MAP.MAX_ZOOM) * MAP.MAX_RESOLUTION;

// Coordinate system config given by MapTiler
MAP.CRS = L.CRS.Simple;
MAP.CRS.transformation = new L.Transformation(
  1,
  -MAP.VIEW_BOUNDS[0],
  -1,
  MAP.VIEW_BOUNDS[3]
);
MAP.CRS.scale = (zoom) => Math.pow(2, zoom) / MAP.MIN_RESOLUTION;
MAP.CRS.zoom = (scale) => Math.log(scale * MAP.MIN_RESOLUTION) / Math.LN2;

/**
 * Gets a point in the map own coordinate system
 * when working outside of the <Map /> component
 * (inside the component, the CRS is defined and normalized)
 */
MAP.getPoint = (lat, lng) => MAP.CRS.unproject(L.point(lng, lat));

MAP.VIEW_BOUNDS = [
  MAP.getPoint(MAP.VIEW_BOUNDS[3], MAP.VIEW_BOUNDS[2]),
  MAP.getPoint(MAP.VIEW_BOUNDS[1], MAP.VIEW_BOUNDS[0]),
];

MAP.INNER_BOUNDS = [MAP.getPoint(-10000, 1120), MAP.getPoint(-3500, 18460)];

/**
 * Modal config
 */

const MODAL = {
  // Offset between base of marker and modal
  OFFSET: 42,
};

/**
 * Mediatypes config
 */

const MEDIA_TYPES = {
  LINK: 1,
  VIDEO: 2,
  IMAGE: 3,
};

/**
 * Demo locales
 * Will fetch a local json in demo-locales folder instead of backend
 */

const DEMO_LOCALES = [];

const COOKIES = {
  SEEN_TUTORIAL: "seen_tutorial",
  ACCEPT_COOKIES: "accept_cookies",
};

const MARKER_POSITIONS = [
  // Center
  [
    {
      "lat": -7746,
      "lng": 18080,
    },
    {
      "lat": -7512,
      "lng": 1572,
    },
  ],
  // Right
  [
    {
      "lat": -7273,
      "lng": 1712,
    },
  ],
  // Left
  [
    {
      "lat": -7070,
      "lng": 18176,
    },
  ],
];

export {
  DEBUG,
  MAP,
  SPOTS,
  MODAL,
  MEDIA_TYPES,
  DEMO_LOCALES,
  COOKIES,
  DEFAULT_MAPSLUG,
  MARKER_POSITIONS
};
