import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { cn } from "../../helpers";

const Spinner = ({ className = "", delay = 0, light }) => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowing(true);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return isShowing ? (
    <div className={cn("Spinner", light && "Spinner--light", className)} />
  ) : null;
};

Spinner.propTypes = {
  className: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  light: PropTypes.bool,
};

Spinner.defaultProps = {
  className: "",
  delay: 0,
};

export default Spinner;
