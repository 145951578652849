import React, { memo } from "react";

const ZoomIn = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="400"
    height="400"
    viewBox="0 0 400 400"
  >
    <path d="M199.995 0C89.716 0 0 89.72 0 200c0 110.279 89.716 200 199.995 200C310.277 400 400 310.279 400 200 400 89.72 310.277 0 199.995 0zm0 373.77C104.182 373.77 26.23 295.816 26.23 200c0-95.817 77.951-173.77 173.765-173.77 95.817 0 173.772 77.953 173.772 173.77.002 95.816-77.955 173.77-173.772 173.77z" />
    <path d="M279.478 186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115 5.873-13.115 13.115v66.368h-66.361c-7.242 0-13.115 5.873-13.115 13.115 0 7.243 5.873 13.115 13.115 13.115h66.358v66.362c0 7.242 5.872 13.114 13.115 13.114 7.242 0 13.115-5.872 13.115-13.114v-66.365h66.367c7.241 0 13.114-5.873 13.114-13.115 0-7.243-5.873-13.116-13.115-13.116z" />
  </svg>
));

export default ZoomIn;
