import React, { memo } from "react";

const Cross = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <g>
      <path d="M4.058 30.182A2.377 2.377 0 11.696 26.82L26.821.696a2.377 2.377 0 113.362 3.362L4.058 30.182z" />
      <path d="M.696 4.058A2.377 2.377 0 114.058.696l26.125 26.125a2.377 2.377 0 11-3.362 3.362L.696 4.058z" />
    </g>
  </svg>
));

export default Cross;
