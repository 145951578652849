export const getPreferredLocale = ({ available, defaultLocale }) => {
  // First we try to get browser locale
  const browserLocale = Object.keys(available).find(
    key => key === navigator.language.split("-")[0]
  );

  if (browserLocale) return browserLocale;
  
  return defaultLocale;
};
