import React from "react";

const Veolia = ({ className }) => (
  <svg
    version="1.1"
    viewBox="0 0 342.92999 130.16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ffffff"
      fillRule="evenodd"
      strokeWidth="0"
      d="m 65.11,0 h 212.64 c 36.06,0 65.18,29.01 65.18,65.08 0,36.07 -29.12,65.08 -65.18,65.08 H 65.11 C 29.05,130.16 0,101.15 0,65.08 0,29.01 29.05,0 65.11,0 Z"
      id="path132"
    />
    <g id="g148" transform="translate(-79.01,-85.17)">
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 178.63,150.25 c 0,19.07 -15.38,34.54 -34.33,34.54 -18.95,0 -34.26,-15.47 -34.26,-34.54 0,-19.07 15.31,-34.54 34.26,-34.54 18.95,0 34.33,15.47 34.33,34.54 z m -26.8,3.21 c 1.15,5.33 -3.13,11.81 -7.34,16.57 13.33,-0.19 24.24,-11.11 24.24,-24.59 0,-13.48 -10.91,-24.53 -24.44,-24.53 -13.53,0 -24.37,10.98 -24.37,24.53 0,13.55 10.91,24.4 24.24,24.59 -4.27,-4.75 -8.49,-11.24 -7.4,-16.57 0.96,-5.33 4.91,-7.13 7.53,-7.06 2.68,-0.06 6.64,1.73 7.53,7.06"
        id="path134"
      />
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 285.56,170.78 c -5.18,0 -10.17,-1.36 -13.24,-3.55 -3.9,-2.84 -6.08,-8.07 -6.08,-14.78 0,-12.59 6.08,-18.72 18.74,-18.72 12.66,0 18.81,6.2 18.81,18.98 0,11.88 -6.27,18.07 -18.23,18.07 m -0.52,-30.86 c -6.91,0 -9.85,3.68 -9.85,12.26 -0.06,8.52 3.07,12.46 9.91,12.46 6.84,0 9.79,-3.94 9.79,-12.52 0,-8.58 -3.13,-12.2 -9.85,-12.2"
        id="path136"
      />
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 327.96,163.61 c -4.96,0 -6.55,-0.32 -7.88,-1.48 -1.27,-1.09 -1.65,-1.86 -1.78,-5.39 v -18.11 c -0.06,-1.86 -1.59,-3.34 -3.5,-3.4 h -4.51 v 19.45 c 0,6.04 0.57,8.73 2.61,11.11 2.35,2.76 5.78,3.98 11.19,3.98 h 10.74 v -6.16 h -6.86 z"
        id="path138"
      />
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 376.32,135.23 h -5.48 c -2.13,0.06 -3.93,1.28 -4.7,3.02 l -13.79,31.53 h 5.61 c 2.13,0 4,-1.28 4.83,-3.02 l 2.13,-5.01 h 13.85 l 2.19,5.01 c 0.84,1.8 2.64,3.02 4.83,3.02 h 5.61 l -15.08,-34.54 z m -9.67,20.42 5.22,-12.14 5.15,12.14 z"
        id="path140"
      />
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 343.82,135.23 c -1.94,0.06 -3.49,1.54 -3.49,3.34 v 31.2 h 4.52 c 1.94,0 3.49,-1.48 3.49,-3.34 v -31.2 z"
        id="path142"
      />
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 222.53,135.23 c -2.14,0.06 -3.96,1.28 -4.8,3.02 l -8.5,21.25 -9.15,-21.19 c -0.84,-1.8 -2.66,-3.02 -4.86,-3.08 h -5.58 l 15.44,34.54 h 4.48 c 2.08,-0.06 3.83,-1.22 4.67,-2.89 l 14.47,-31.65 h -6.16 z"
        id="path144"
      />
      <path
        fill="#ff0000"
        fillRule="evenodd"
        strokeWidth="0"
        d="m 230.69,152.76 c 0,3.4 0.76,12.58 8.98,15.99 1.91,0.71 3.95,1.03 6.94,1.03 h 13.62 v -6.16 h -10.5 c -4.97,-0.06 -7.7,-1.09 -9.04,-3.85 -0.51,-1.03 -0.76,-2.38 -0.95,-3.98 v -0.32 h 19.61 v -5.91 h -19.61 v -0.32 c 0.19,-1.61 0.45,-2.95 0.95,-3.98 1.34,-2.76 4.07,-3.79 9.04,-3.85 h 10.5 v -6.16 h -13.62 c -2.99,0 -5.03,0.32 -6.94,1.03 -8.21,3.4 -8.98,12.58 -8.98,15.99 v 0.51 z"
        id="path146"
      />
    </g>
  </svg>
);

export default Veolia;
