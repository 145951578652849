import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import Spinner from "../../../commons/components/Spinner";
import Media from "../../../commons/components/Media";
import AttachmentsList from "../../../commons/components/AttachmentsList";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CrossIcon,
  VideoIcon,
  ImageIcon,
  ChevronIcon,
} from "../../../commons/svg";

import { cn, useDictionary, useGlobalState } from "../../../commons/helpers";
import { MEDIA_TYPES } from "../../../commons/config";

const Modal = ({ setRef, onModalMount, onModalClose, spotId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeMediaIndex, setActiveMediaIndex] = useState(0);

  const string = useDictionary();
  const { activeSpot: spot } = useGlobalState();

  const hasSpot = !!spot;
  const hasDetails = hasSpot && spot.chapo;

  // SpotId prop comes from the url.
  // This effect fetches associated data whenever the url changes.
  useEffect(
    () => {
      onModalMount(spotId);
    },
    [spotId]
  );

  let mainMedia = null;

  if (hasDetails) {
    mainMedia =
      1 === spot.media_type // Image
        ? {
            type_id: MEDIA_TYPES.IMAGE,
            legend: spot.image_title,
            url_image: spot.image_url,
          }
        : {
            type_id: MEDIA_TYPES.VIDEO,
            legend: spot.video_title,
            url_video: spot.video_url,
          };
  }

  // We add the featured video as a first media in attachments list
  const attachments = mainMedia ? [mainMedia, ...(spot.attachments || [])] : [];

  // List without active media
  const attachmentsList = [...attachments];
  attachmentsList[activeMediaIndex] = null;

  // Active media
  const activeMedia = attachments[activeMediaIndex];
  console.log("activeMedia", activeMedia);

  const renderDetails = () => {
    return (
      <Fragment>
        <Media
          key={activeMediaIndex} // We want a unique key to trigger loading state when state changes
          {...activeMedia}
        />

        <p className="Modal__chapo">{spot.chapo}</p>

        <footer className="Modal__footer">
          <button
            className="Modal__expand"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {string("spot.learn_more")}
            {isExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </button>
        </footer>
      </Fragment>
    );
  };

  return (
    <div
      className={cn("Modal", isExpanded && "sidebar-is-expanded")}
      ref={setRef}
    >
      {hasSpot ? (
        <Fragment>
          <div className="Modal__main">
            <header className="Modal__header">
              <h2 className="Modal__title">{spot.title}</h2>
              <button onClick={onModalClose}>
                <CrossIcon />
              </button>
            </header>
            <div className="Modal__inner">
              {hasDetails ? (
                renderDetails()
              ) : (
                <div className="Modal__loader">
                  <Spinner delay={250} />
                </div>
              )}
            </div>
          </div>
          {hasDetails && (
            <div className="Modal__sidebar">
              <div className="Modal__sidebar__header">
                <button onClick={() => setIsExpanded(false)}>
                  <ArrowLeftIcon />
                </button>
              </div>
              <AttachmentsList
                attachments={attachmentsList}
                activeIndex={activeMediaIndex}
                onItemClick={index => setActiveMediaIndex(index)}
              />
              <div className="Modal__sidebar__current">
                <header>
                  <span className="icon">
                    <ChevronIcon />
                  </span>
                  <span className="title">{string("spot.current_media")}</span>
                </header>
                <div className="AttachmentsList__item is-active">
                  {activeMedia.type_id === MEDIA_TYPES.VIDEO ? (
                    <VideoIcon />
                  ) : (
                    <ImageIcon />
                  )}
                  <span>{activeMedia.legend}</span>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : null}
    </div>
  );
};

Modal.propTypes = {
  setRef: PropTypes.object.isRequired,
  onModalMount: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  spotId: PropTypes.string,
};

export default Modal;
