import { useState, useEffect } from "react";

const getOrientation = () => {
  const { innerWidth, innerHeight } = window;
  return innerWidth > innerHeight ? "landscape" : "portrait";
};

const useOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = () => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  return orientation;
};

export default useOrientation;
