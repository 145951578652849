import React, { createContext, useContext } from "react";

const DictionaryContext = createContext({});

const DictionaryProvider = ({ children, dictionary }) => (
  <DictionaryContext.Provider
    value={{ getStringFromDictionary: key => dictionary[key] || key }}
  >
    {children}
  </DictionaryContext.Provider>
);

const useDictionary = () => {
  const dictionary = useContext(DictionaryContext);
  return dictionary.getStringFromDictionary;
};

export { DictionaryProvider, useDictionary };
