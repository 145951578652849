import React, { useState } from "react";
import PropTypes from "prop-types";

import { ChevronIcon } from "../../../commons/svg";

import { cn, useGlobalState } from "../../../commons/helpers";

const SpotsList = ({ onSpotClick, onSpotMouseEnter, onSpotMouseLeave }) => {
  const [openCategory, setOpenCategory] = useState(null);
  const { spots, activeSpot } = useGlobalState();

  return (
    <div className="SpotsList">
      {spots.map(category => {
        const isOpen = openCategory === category.id;

        return (
          <div
            key={category.id}
            className={cn("SpotsList__item", isOpen && "is-open")}
          >
            <button
              className="SpotsList__item__trigger"
              onClick={() => {
                // Toggles if trigger is clicked when open
                const nextOpenCategory =
                  openCategory !== category.id ? category.id : null;

                setOpenCategory(nextOpenCategory);
              }}
            >
              {/* <img src={category.logo_light} /> */}
              {category.label}

              <ChevronIcon />
            </button>
            <ul className="SpotsList__item__list">
              {category.spots.map(spot => {
                const isActive = activeSpot ? activeSpot.id === spot.id : false;
                return (
                  <li
                    key={spot.id}
                    className={cn(
                      "SpotsList__item__spot",
                      isActive && "is-active"
                    )}
                  >
                    <button
                      onClick={() => onSpotClick(spot)}
                      onMouseEnter={() => onSpotMouseEnter(spot.id)}
                      onMouseLeave={() => onSpotMouseLeave()}
                    >
                      {spot.title}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

SpotsList.propTypes = {
  onSpotClick: PropTypes.func.isRequired, 
  onSpotMouseEnter: PropTypes.func.isRequired, 
  onSpotMouseLeave: PropTypes.func.isRequired,
};

export default SpotsList;
