import React from "react";
import PropTypes from "prop-types";

import Hamburger from "../../../commons/components/Hamburger";
import LocaleSwitcher from "../../../commons/components/LocaleSwitcher";
import Drawer from "../Drawer";
import VeoliaLogo from "../../../commons/svg/Veolia";

import { useDictionary, useGlobalState } from "../../../commons/helpers";

const Header = ({
  toggleTutorial,
  toggleDrawer,
  onSpotClick,
  onSpotMouseEnter,
  onSpotMouseLeave,
}) => {
  const string = useDictionary();
  const { showTutorial, showDrawer, showContentPage } = useGlobalState();

  return (
    <header className="Header">
      <div className="Header__logo">
        <VeoliaLogo />
      </div>
      <h1 className="Header__title">{string("header.title")}</h1>

      <div className="Header__actions">
        <LocaleSwitcher />
        <Hamburger
          isActive={showDrawer}
          disabled={showTutorial || showContentPage}
          onClick={() => toggleDrawer(!showDrawer)}
        />
      </div>
      <Drawer
        isOpen={showDrawer}
        onSpotClick={onSpotClick}
        onSpotMouseEnter={onSpotMouseEnter}
        onSpotMouseLeave={onSpotMouseLeave}
        toggleTutorial={toggleTutorial}
      />
    </header>
  );
};

Header.propTypes = {
  toggleTutorial: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onSpotClick: PropTypes.func.isRequired,
  onSpotMouseEnter: PropTypes.func.isRequired,
  onSpotMouseLeave: PropTypes.func.isRequired,
};

export default Header;
