import React from "react";
import PropTypes from "prop-types";

import { cn } from "../../helpers";

const Hamburger = ({ isActive, onClick, disabled, className, ...props }) => {
  return (
    <button
      className={cn("Hamburger", className, isActive && "is-active")}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <div className="Hamburger__main">
        <div className="Hamburger__inner" />
      </div>
    </button>
  );
};

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Hamburger;
