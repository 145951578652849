import React, { useEffect } from "react";
import Cookie from "js-cookie";
import PropTypes from "prop-types";

import Loader from "../../../commons/components/Loader";
import Spinner from "../../../commons/components/Spinner";
import Toast from "../../../commons/components/Toast";
import Auth from "../../../commons/components/Auth";
import Header from "../Header";
import Footer from "../Footer";
import Tutorial from "../Tutorial";

import { cn, useGlobalState, usePageTracking } from "../../../commons/helpers";
import { COOKIES } from "../../config";

const Layout = ({
  isLoaderShown,
  showMapLoader,
  toggleDrawer,
  toggleTutorial,
  onZoomClick,
  onSpotClick,
  onSpotMouseEnter,
  onSpotMouseLeave,
  onResetClick,
  onSuccessAuth,
  onAppMount,
  onSlugChange,
  apiError,
  children,
  GATag,
  // From URL
  mapSlug,
}) => {
  const { showTutorial, showAuth, showUI } = useGlobalState();

  useEffect(
    () => {
      onAppMount();
    },
    []
  );

  useEffect(
    () => {
      if (!mapSlug) return;
      onSlugChange(mapSlug);
    },
    [mapSlug]
  );

  return (
    <div className={cn("Layout", showUI && "is-ui-shown")}>
      {apiError && <Toast {...apiError} showUI={showUI} />}
      <Loader isLoading={isLoaderShown && !showAuth} />
      <Header
        toggleTutorial={toggleTutorial}
        toggleDrawer={toggleDrawer}
        onSpotClick={onSpotClick}
        onSpotMouseEnter={onSpotMouseEnter}
        onSpotMouseLeave={onSpotMouseLeave}
      />
      {showTutorial && (
        <Tutorial
          onHide={() => {
            Cookie.set(COOKIES.SEEN_TUTORIAL, "1");
            toggleTutorial(false);
          }}
        />
      )}
      <Auth isVisible={showAuth} onSuccess={onSuccessAuth} />
      <Spinner
        className={cn("Layout__spinner", showMapLoader && "is-visible")}
        light
      />
      {children}
      <Footer onZoomClick={onZoomClick} onResetClick={onResetClick} />
    </div>
  );
};

Layout.propTypes = {
  isLoaderShown: PropTypes.bool.isRequired,
  showMapLoader: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  toggleTutorial: PropTypes.func.isRequired,
  onZoomClick: PropTypes.func.isRequired,
  onSpotClick: PropTypes.func.isRequired,
  onSpotMouseEnter: PropTypes.func.isRequired,
  onSlugChange: PropTypes.func.isRequired,
  onSpotMouseLeave: PropTypes.func.isRequired,
  onSuccessAuth: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onAppMount: PropTypes.func.isRequired,
  GATag: PropTypes.string,
  mapSlug: PropTypes.string,
  apiError: PropTypes.object,
  children: PropTypes.node,
};

export default Layout;
