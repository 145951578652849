import { useEffect, useMemo, useState } from "react";
import { useLocation } from "@reach/router";
import ReactGA from "react-ga";

import { useGlobalState } from "./global-state";
import { useDictionary } from "./dictionary";
import useOrientation from "./orientation";
import { MARKER_POSITIONS } from "../config";

const cn = (...classes) => classes.filter(Boolean).join(" ");

const flattenSpots = spots =>
  spots.reduce((total, current) => total.concat(current.spots), []);

const createRouteGetter = (routes, hasSpecificDomain) => {
  return (routeName, params) => {
    let path = routes[routeName];

    if (!path)
      return console.error(
        `createRouteGetter: No path declared for this routeName: ${routeName}`
      );

    if (false === hasSpecificDomain) {
      path = "/:mapSlug" + path;
    }

    Object.keys(params).map(key => {
      path = path.replace(`:${key}`, params[key]);
    });

    return path;
  };
};

function debounce(callback, wait, immediate = false) {
  let timeout = null;

  return function() {
    const callNow = immediate && !timeout;
    const next = () => callback.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
}

const useLogger = (title, state) => {
  useEffect(
    () => {
      console.groupCollapsed(title);
      console.log(state);
      console.groupEnd(title);
    },
    [state]
  );
};

const usePageTracking = (GATag, isSonata) => {
  if (isSonata) {
    return false;
  }
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(
    () => {
      if (!GATag || !active || initialized) return;
      console.log(`Initializing GA with id: ${GATag}`);
      ReactGA.initialize(GATag);
      setInitialized(true);
    },
    [GATag, active]
  );

  useEffect(
    () => {
      if (initialized) {
        console.log(location.pathname);
        ReactGA.pageview(location.pathname);
      }
    },
    [initialized, location]
  );

  return () => setActive(true);
};

const generateViewMarkers = ids => {
  const markers = {};

  MARKER_POSITIONS.forEach((positions, index) => {
    switch (index) {
      case 0: // Center
        markers[ids[0]] = [...positions];
        markers[ids[0]][0].id = ids[1];
        markers[ids[0]][1].id = ids[2];
        break;
      case 1: // Right
        markers[ids[1]] = [...positions];
        markers[ids[1]][0].id = ids[0];
        break;
      case 2: // Left
        markers[ids[2]] = [...positions];
        markers[ids[2]][0].id = ids[0];
        break;
    }
  });

  return markers;
};

export {
  cn,
  flattenSpots,
  createRouteGetter,
  useLogger,
  useDictionary,
  useGlobalState,
  useOrientation,
  usePageTracking,
  debounce,
  generateViewMarkers,
};
