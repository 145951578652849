import React, { memo } from "react";

const Image = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 439.296 439.296">
    <path d="M421.376 46.336H17.92C8.192 46.336 0 54.528 0 64.256V375.04c0 9.728 8.192 17.92 17.92 17.92h403.456c9.728 0 17.92-8.192 17.92-17.92V64.256c0-10.24-7.68-17.92-17.92-17.92zm-17.92 214.528l-73.216-58.368c-6.656-5.12-16.384-5.12-23.04.512l-82.432 71.68-109.056-122.88c-3.584-3.584-8.704-6.144-13.824-6.144s-9.728 2.048-13.312 5.632L35.84 208.64V82.176h367.616v178.688z" />
    <path d="M219.648 92.928c-32.256 0-58.88 26.624-58.88 58.88s26.112 58.88 58.88 58.88 58.88-26.624 58.88-58.88c0-32.768-26.624-58.88-58.88-58.88z" />
  </svg>
));

export default Image;
