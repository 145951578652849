import React from "react";
import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";

import ComponentMarker from "../ComponentMarker";

import { cn, useDictionary } from "../../helpers";

const SpotMarker = ({
  spot,
  type,
  width,
  height,
  position,
  index,
  isLast,
  showLabel,
  active,
  hovered,
  animated,
  setAnimateSpots,
  onClick,
  draggable,
  onDragEnd,
  onMouseOver,
  onMouseLeave,
}) => {
  const showInfos = !active && (showLabel || hovered);

  const string = useDictionary();

  const tailVariants = {
    hidden: { scaleY: 0 },
    visible: { scaleY: 1 },
  };

  const headAnimation = useAnimation();
  const headVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1 },
  };

  return (
    <ComponentMarker
      className={cn(
        "SpotMarker",
        type && `SpotMarker--${type}`,
        active && "is-active",
        hovered && "is-hovered"
      )}
      position={position}
      iconAnchor={[width / 2, height]}
      iconSize={[width, height]}
      onClick={onClick}
      draggable={draggable}
      onDragEnd={onDragEnd}
    >
      <div className="SpotMarker__inner">
        <motion.div
          className="SpotMarker__head"
          initial={animated ? "hidden" : "visible"}
          animate={headAnimation}
          transition={{
            stiffness: 20,
            mass: 0.2,
          }}
          variants={headVariants}
          onMouseOver={onMouseOver}
          onMouseEnter={onMouseOver}
          onHoverStart={onMouseOver}
          onHoverEnd={onMouseLeave}
          onAnimationComplete={() => {
            if (isLast) setAnimateSpots(false);
          }}
        />
        {showInfos && (
          <motion.div className="SpotMarker__infos" onClick={onClick}>
            {spot.new && <span className="new">{string("spot.new")}</span>}
            <span className="label">{spot.title}</span>
          </motion.div>
        )}
        <motion.span
          className="SpotMarker__tail"
          initial={animated ? "hidden" : "visible"}
          animate={animated && "visible"}
          variants={tailVariants}
          transition={{
            delay: index * 0.05,
            duration: 0.4,
            ease: [0.4, 0.0, 0.2, 1.1],
          }}
          onAnimationComplete={() => headAnimation.start({ scale: 1 })}
          style={{ height: height - width }}
        />
      </div>
    </ComponentMarker>
  );
};

SpotMarker.propTypes = {
  spot: PropTypes.shape({
    new: PropTypes.bool,
    title: PropTypes.string,
  }),
  type: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  position: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  isLast: PropTypes.bool,
  showLabel: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  hovered: PropTypes.bool.isRequired,
  animated: PropTypes.bool.isRequired,
  setAnimateSpots: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

SpotMarker.defaultProps = {
  animated: false,
  showLabel: false,
  hovered: false,
};

export default SpotMarker;
